import React from 'react';

const TopAd = () => {
  const imageStyle = {
    maxWidth: '92vw',
    height: 'auto',
  };

  return (
    <div className="top-ad">
      <a href="https://rollosson.com">
        <img src="rollo-banner.jpg" alt="click if you feel like it" style={imageStyle}/>
      </a>
    </div>
  );
};

export default TopAd;