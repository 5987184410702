import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Contract from './Contract';
import Summary from './Summary';

function FileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [contractData, setContractData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const loadingMessages = [
    'Uploading the Document',
    'Recognizing Data Format',
    'Checking Format',
    'Reading the Document',
    'Converting Data',
    'Analyzing Text Structure',
    'Categorizing Information',
    'Analyzing Content',
    'Extracting Titles',
    'Identifying Keywords',
    'Identifying Entities',
    'Scanning for Terms',
    'Summarizing Content',
    'Analyzing Language',
    'Scanning for Insights',
    'Detecting Key Phrases',
    'Checking for Redactions',
    'Searching for References',
    'Cross-Referencing Information',
    'Assessing Relevance',
    'Identifying Dependencies',
    'Checking for Accuracy',
    'Evaluating Structure',
    'Assessing Importance',
    'Searching for Citations',
    'Assessing Completeness',
    'Cross-Checking Data',
    'Evaluating Clarity',
    'Evaluating Readability',
    'Assessing Flow',
    'Evaluating Coherence',
    'Identifying Inconsistencies',
    'Analyzing Definitions',
    'Analyzing Syntax',
    'Assessing Terminology',
    'Identifying Anomalies'
];

useEffect(() => {
  const interval = setInterval(() => {
    setLoadingMessageIndex((prevIndex) =>
      prevIndex === loadingMessages.length - 1 ? 0 : prevIndex + 1
    );
  }, 2500);

  return () => clearInterval(interval);
}, []);

const openDialog = (message) => {
  setDialogOpen(true);
  setDialogMessage(message);
};

const fileInputRef = useRef(null);

const handleFileChange = async (event) => {
  const file = event.target.files[0];

  if (!file) {
    return; // No file selected
  }

  setSelectedFile(file);
  setContractData(null);
  setSummaryData(null);
  setLoading(true); // Show loading spinner

  try {
    const formData = new FormData();
    formData.append('pdfFile', file);

    console.log(process.env.REACT_APP_API_ENDPOINT)
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, formData, {
      timeout: 120000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const result = JSON.parse(response.data)
    const contract = result.contract
    const summary = result.summary
    console.log(typeof(summary))
    console.log(summary)
    setContractData(contract);
    setSummaryData(summary);
    setValid(contract.valid);
  } catch (error) {
    console.error(error);
    openDialog('An error occurred while processing the PDF.');
  } finally {
    setLoading(false); // Hide loading spinner
  }
};

const handleUploadButtonClick = () => {
  // Trigger a click event on the hidden file input
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};

const renderSummary = (valid, contractData, summaryData) => {
    console.log(valid)
    if (!contractData || !contractData.subject) {
      return <Typography sx={{mt:'20px'}} variant="body1">No summary available.</Typography>;
    }

    if (valid) {
        console.log('renderSummary is valid')
        console.log(summaryData)
        return (
            <Grid sx={{mt:'20px'}} >
                <Summary subject={contractData.subject} summaryText={summaryData} />
            </Grid>
        );
    } else {
        return (
            <Grid sx={{mt:'20px'}} >
                <Typography variant="body1">Sorry, this doesn't look like a contract to me.</Typography>
            </Grid>
        )
    }
  };

return (
  <Container sx={{ ml: '-20px', mb: '120px' }}>
    <Grid container>
      <Grid item className="headline" xs={12} sx={{ padding:'10px' }}>
        <Grid container>
            <Grid item xs={8}>
                <Typography variant="h3">docslap</Typography>
                <Typography variant="h4" sx={{ fontSize:'.7em', mt: '-10px' }}>
                &nbsp; AI your contracts
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{mt:'10px'}}>
                <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                ref={fileInputRef} // Ref for the hidden file input
                style={{ display: 'none' }} // Hide the file input
                />
                <label htmlFor="upload-button">
                {/* "Upload PDF" button */}
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={handleUploadButtonClick}
                    component="span" // Make it look like a button
                    id="upload-button" // Match the label's "for" attribute
                >
                    Upload PDF
                </Button>
                </label>
            </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8} sx={{ mt: '30px' }}>
      </Grid>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Well...</DialogTitle>
        <DialogContent>{dialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
    {loading && (
        <div
            style={{
            left:'0px',
            width:'100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '50vh', // Adjust this value for desired centering
            }}
        >
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: '16px' }}>
            {loadingMessages[loadingMessageIndex]}
            </Typography>
        </div>
        )}

    {!loading && summaryData && (        
      <div>
            <>
            {renderSummary(valid, contractData, summaryData)}
            </>
      </div>
    )}
  </Container>
);
}

export default FileUpload;