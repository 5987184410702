import React from 'react';
import './App.css';
import FileUpload from './components/FileUpload';
import TopAd from './components/TopAd';
import RightAd from './components/RightAd';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider and createTheme
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';

// Create a custom theme
const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: '#8426bf', // Example primary color
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" className="app-container">
        <Grid container>
          <Grid item xs={12} lg={9}>
            {/* Left column */}
            <div className="bottom-fixed">
              <TopAd />
            </div>

            {/* Center column with file upload */}
            <div className="center-column">
              <FileUpload/>
            </div>
          </Grid>
          <Grid item xs={3}>
            {/* Right column */}
            <div className="right-column">
            </div>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default App;
