// Summary.js
import React from 'react';
import { Typography, Grid } from '@mui/material';


const Summary = ({ subject, summaryText }) => {
  const summary = typeof(summaryText) == 'string' ? JSON.parse(summaryText) : summaryText
  return (
    <>
        <Typography sx={{mt:'10px'}} variant="h4">Ok, here's the skinny:</Typography>
        <Typography sx={{mt:'20px'}} variant="h5">{subject}</Typography>
        {Object.keys(summary).map((title, index) => (
            <Grid  sx={{mt:'20px', mb:'30px'}} key={title + index} className="title">
                <Typography sx={{mt:'10px'}} variant="h5">{title}</Typography>
                {summary[title].map((item, index) => (
                <Typography variant="body1" className="item" key={item+index}>
                    {item}
                </Typography>
                ))}
            </Grid>
        ))}
    </>
  );
};

export default Summary;